import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserManagement } from '../Api/UserManagement';
import { Toast } from '../hooks/useToast';
import { updateReportStatus } from './ReportManagementReducer';

export const fetchUserManagementThunk = createAsyncThunk(
    'userManangement/fetch',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await UserManagement.fetch({ api_token });
            return response.data
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err);
        }
    }
)

export const updateUserManagementThunk = createAsyncThunk(
    'userManangement/update',
    async (data, { getState, dispatch }) => {
        try {
            const { api_token } = getState().profile.data;
            dispatch(updateStatus(data));
            const response = await UserManagement.update({ data, api_token });
            dispatch(updateReportStatus({ id: data.user_id }))
            return response.data
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);
        }
    }
)

const initialState = {
    status: "pending",
    data: [],
}

export const userManagementSlice = createSlice({
    name: 'userManagement',
    initialState,
    reducers: {
        resetState: () => {
            return initialState
        },
        setStatus: (state, actions) => {
            return {
                ...state,
                ...actions.payload
            }
        },
        updateStatus: (state, actions) => {
            return {
                ...state,
                data: [
                    ...state.data.map(item => {
                        const { user_id } = actions.payload;
                        if (item.id === user_id) {
                            return {
                                ...item,
                                status: !item.status,
                            }
                        }
                        else {
                            return item;
                        }
                    })
                ]
            }
        }
    },
    extraReducers: {
        [fetchUserManagementThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [fetchUserManagementThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [updateUserManagementThunk.fulfilled]: (state, action) => {
            state.status = "success"
        },
        [updateUserManagementThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [updateUserManagementThunk.rejected]: (state, action) => {
            state.status = "error"
        },
    }

})


// Action creators are generated for each case reducer function
export const { setStatus, updateStatus, resetState } = userManagementSlice.actions

export default userManagementSlice.reducer