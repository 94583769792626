import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Toast } from "../../hooks/useToast";
import { confirmAlert } from 'react-confirm-alert';

/**Import Styles and Assets */
import styles from "./Faqs.module.scss";

import { deleteFaqThunk, fetchStaticTextThunk, insertFaqThunk, updateFaqThunk } from "../../features/StaticTextReducer";


import { Collapse, Space } from 'antd';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ImCancelCircle } from "react-icons/im";
import { IoIosAddCircle } from "react-icons/io";
import { BiSend } from "react-icons/bi";

import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import Modal from "../../components/Modal";

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 35, color: "black" }} spin />;

export default function Faqs() {
    const [isModal, setIsModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const { data, status } = useSelector(state => state.staticText);
    const dispatch = useDispatch();
    const [isEditable, setIsEditable] = useState(false);
    const [newFaq, setNewFaq] = useState({
        question: "",
        answer: ""
    })
    const ref = useRef(null);


    useEffect(() => {
        if (status !== "success") {
            dispatch(fetchStaticTextThunk());
        }
    }, [])

    useEffect(() => {
        setNewFaq(newFaq);
        setIsEditable(false);
    }, [data])



    function handleChange(e) {
        const { name, value } = e.target;
        setNewFaq(prev => {
            return {
                ...prev,
                [name]: value
            }
        })

    }

    function handleSend() {
        if (newFaq.question && (newFaq.question.length > 100)) return Toast("Question should contain max 100 character", 'error', false);
        if (newFaq.question && newFaq.answer) {
            const body = {
                question: newFaq.question,
                answer: newFaq.answer,
                contentType: "faq"
            }
            dispatch(insertFaqThunk(body));
        }
        else {
            Toast("Question and Answer should not be empty", 'info', false)
        }
    }

    function cancelEdit() {
        setIsEditable(false);
    }

    function handleUpdateFaq(data) {
        if (data.question && (data.question.length > 100)) return Toast("Question should contain max 100 character", 'error', false);
        if (data.question && data.answer) {
            dispatch(updateFaqThunk({ data, setIsModal }));
        }
        else {
            Toast("Question or Answer should not be empty", 'info', false)
        }

    }

    function submit(title, message, cb, rejectCB = () => { }) {
        confirmAlert({
            title,
            message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => cb()
                },
                {
                    label: 'No',
                    onClick: () => rejectCB()
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
        });
    }



    const genExtra = (item) => (
        <div className={styles.edit}>
            <button value={JSON.stringify(item)} className={styles.deleteFaq} onClick={(e) => {
                setModalData(e.currentTarget.value)
                setIsModal(true);
            }}>
                <AiFillEdit />
            </button>
            <button value={item.id} className={styles.deleteFaq} onClick={(e) => {
                ref.current = e.currentTarget.value;
                submit("Confirm Deletion", 'Are you sure you want to delete this faq?', () => dispatch(deleteFaqThunk({ id: ref.current })))
            }}>
                <AiFillDelete />
            </button>
        </div>

    );
    return (
        <div className={styles.faqs}>
            {isModal ? <Modal data={modalData} setIsOpen={setIsModal} handleSave={handleUpdateFaq} /> : ""}
            <div className={styles.title}>
                <h1 className={styles.contentTitle}>Faqs</h1>
                {
                    !isEditable ?
                        <button onClick={() => { setIsEditable(true) }}><IoIosAddCircle /></button> :
                        <div className={styles.save}>
                            <button onClick={cancelEdit}><ImCancelCircle /></button>
                        </div>

                }
            </div>
            <div className={styles.content} >
                {isEditable &&
                    <div className={styles.newFaq}>
                        <div className={styles.submitFaq}>
                            <input type="text" placeholder="Question (Max : 100 character)" name="question" value={newFaq.question} onChange={handleChange} />
                            <button onClick={handleSend}><BiSend /></button>
                        </div>
                        <textarea rows={5} placeholder="Answer" name="answer" value={newFaq.answer} onChange={handleChange} />
                    </div>
                }
                {data?.faq ? data.faq.map((item, index) => {
                    return <Space direction="vertical" className={styles.space}>
                        <Collapse collapsible="header" expandIconPosition="start" ghost>
                            <Panel header={item.question} key={index} extra={genExtra(item)}>
                                <p>{item.answer}</p>
                            </Panel>

                        </Collapse>
                    </Space>
                }) : <Spin indicator={antIcon} size="large" />}
            </div>

        </div>
    )
}