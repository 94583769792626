import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import 'antd/dist/antd.min.css';

import ProtectedLayout from "./components/ProtectedLayout";
import ContentLayout from "./components/ContentLayout";

import DashBoard from "./pages/DashBoard";
import UserManagement from "./pages/UserManagement";
import ReportManagement from "./pages/ReportManagement";
import TermsAndCondition from "./pages/TermsAndCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Faqs from "./pages/Faqs";
import Notification from "./pages/Notification";
import Login from "./pages/Login";

import PublicRoute from "./components/Routes/PublicRoute";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import VariablePage from "./pages/VariablePage";
import ChangePassword from "./pages/ChangePassword";
import ContactDetail from "./pages/ContactDetail";
import CategoryManagement from "./pages/CategoryManagement";
import BusinessManagement from "./pages/BusinessManagement";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path={`/login`} element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        } />
        <Route exact path={`/dashboard`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="0" isSearchBar={false}>
              <ContentLayout>
                <DashBoard />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>

        } />
        <Route exact path={`/user-managment`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="1" isSearchBar={true}>
              <ContentLayout>
                <UserManagement />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`/business-category-management`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="2" isSearchBar={true} >
              <ContentLayout>
                <CategoryManagement />
              </ContentLayout>
            </ProtectedLayout >
          </ProtectedRoute>
        } />
        <Route exact path={`/business-management`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="3" isSearchBar={true} >
              <ContentLayout>
                <BusinessManagement />
              </ContentLayout>
            </ProtectedLayout >
          </ProtectedRoute>
        } />

        <Route exact path={`/user-report-management`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="4" isSearchBar={true}>
              <ContentLayout>
                <ReportManagement />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />

        <Route exact path={`/terms-and-conditions`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="5" isSearchBar={false}>
              <ContentLayout>
                <TermsAndCondition />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`/privacy-policy`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="6" isSearchBar={false}>
              <ContentLayout>
                <PrivacyPolicy />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        < Route exact path={`/faqs`} element={
          <ProtectedRoute>
            < ProtectedLayout selectedRoute="7" isSearchBar={false}>
              <ContentLayout>
                <Faqs />
              </ContentLayout>
            </ProtectedLayout >
          </ProtectedRoute>
        } />

        < Route exact path={`/notifications`} element={
          <ProtectedRoute>
            < ProtectedLayout selectedRoute="8" isSearchBar={false} >
              <ContentLayout>
                <Notification />
              </ContentLayout>
            </ProtectedLayout >
          </ProtectedRoute>
        } />
        <Route exact path={`/change-password`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="9" isSearchBar={false} >
              <ContentLayout>
                <ChangePassword />
              </ContentLayout>
            </ProtectedLayout >
          </ProtectedRoute>
        } />
        <Route exact path={`/contact-detail`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="10" isSearchBar={false} >
              <ContentLayout>
                <ContactDetail />
              </ContentLayout>
            </ProtectedLayout >
          </ProtectedRoute>
        } />

        <Route path={`/*`} element={<Navigate to={`/login`} />} />
      </Routes >


    </Router >
  );
}

export default App;
