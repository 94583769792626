import React from "react";

import "./TableLayout.css";

import { Table } from "antd";



export default function TableLayout({ headers, data, status, rowClassName = "", onRowClick = () => { } }) {



    return (

        <Table
            onRow={onRowClick}
            tableLayout="auto"
            loading={status !== "success"}
            columns={headers}
            dataSource={data}
            pagination={{ pageSize: 8, position: ["bottomCenter"], className: "pagination", hideOnSinglePage: true }}
            className={"table"}
            rowClassName={"row" + " " + rowClassName}
        />

    )
}