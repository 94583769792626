import { Axios } from "../hooks/useAxiosInstance";

export const Business = (function () {


    const apis = () => ({
        fetch: async ({ api_token }) => await fetchRecord(api_token),
        updateStatus: async ({ data,api_token }) => await updateRecordStatus(data,api_token),
        fetchDetailsById: async ({ id, api_token }) => await fetchDetailsByIdRecord(id, api_token),
        
    })

    async function fetchRecord(api_token) {
        const url = "https://api.cirgleapp.com/api/admin/getAllBussiness";
        const option = {
            headers: {
                'token': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.get(url, option);
    }
    async function fetchDetailsByIdRecord(id, api_token) {
        const url = `https://api.cirgleapp.com/api/admin/getBussinessDetails?business_id=${id}`;
        const option = {
            headers: {
                'token': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.get(url, option);
    }

    async function updateRecordStatus(data, api_token) {
        const body = {
            business_id: data.business_id
        }
        const url = "https://api.cirgleapp.com/api/admin/" + (data.is_active ? "busiDeactive" : "busiActive");
        const option = {
            headers: {
                'token': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, body, option);
    }


    return apis();

})()
