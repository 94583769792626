import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { resetValue } from "../../features/SearchReducer";
import AllBusiness from "./AllBusiness";
import BusinessDetail from "./BusinessDetail";



export default function BusinessManagement() {
    const [businessId, setBusinessId] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch(resetValue());

    }, [])
    return (businessId !== null) ? <BusinessDetail id={businessId} setBusinessId={setBusinessId} /> : <AllBusiness setBusinessId={setBusinessId} />
}