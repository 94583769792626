import { configureStore } from '@reduxjs/toolkit';
import BusinessReducer from './features/BusinessReducer';
import CategoryManagementReducer from './features/CategoryManagementReducer';
import ConstantReducer from './features/ConstantReducer';
import DashboardReducer from './features/DashboardReducer';

import defaultReducer from './features/DefaultReducer';
import ProfileReducer from './features/ProfileReducer';
import ReportManagementReducer from './features/ReportManagementReducer';
import SearchReducer from './features/SearchReducer';
import StaticTextReducer from './features/StaticTextReducer';
import UserManagementReducer from './features/UserManagementReducer';

export const store = configureStore({
  reducer: {
    default: defaultReducer,
    dashboard: DashboardReducer,
    userManagement: UserManagementReducer,
    reportManagement: ReportManagementReducer,
    staticText: StaticTextReducer,
    constantManagement: ConstantReducer,
    profile: ProfileReducer,
    search: SearchReducer,
    category: CategoryManagementReducer,
    business: BusinessReducer
  },
})