import React, { useEffect, useState } from "react";

/**Import Styles and Assets */
import styles from "./ReportManagement.module.scss";

import TableLayout from "../../components/TableLayout";
import { useReportManagementHeader } from "../../schemas/tableHeaderSchema";
import { useDispatch, useSelector } from "react-redux";
import { fetchReportManagementThunk } from "../../features/ReportManagementReducer";
import { resetValue } from "../../features/SearchReducer";

export default function ReportManagement() {

    const { data, status } = useSelector(state => state.reportManagement);
    const { search } = useSelector(state => state.search);
    const [list, setList] = useState(data);
    const dispatch = useDispatch();
    const header = useReportManagementHeader();

    useEffect(() => {
        if (status !== "success") {
            dispatch(fetchReportManagementThunk());
        }
        return () => dispatch(resetValue());
    }, [])

    useEffect(() => {
        if (!search) return setList(data);


        const array = [
            ...data.map(item => {
                if (item.reportedBy_name.toLowerCase().includes(search.toLowerCase()) || item.reported_name.toLowerCase().includes(search.toLowerCase())) {
                    return item
                }
            })
        ].filter(Boolean)
        setList(array);

    }, [data, search])

    return (
        <div className={styles.reportManagement}>
            <h1 className={styles.contentTitle}>User Report Management</h1>
            <TableLayout headers={header} data={list} status={status}/>
        </div>

    )
}