import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Category } from '../Api/Category';
import { Toast } from '../hooks/useToast';

export const fetchCategoryManagementThunk = createAsyncThunk(
    'category/fetch',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await Category.fetch({ api_token });
            return response.category
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err);
        }
    }
)

export const addCategoryManagementThunk = createAsyncThunk(
    'category/add',
    async ({ name, setIsAddModal }, { getState }) => {
        Toast("Adding Category", 'loading', true);
        try {
            const body = {
                category_name: name
            }
            const { api_token } = getState().profile.data;
            const response = await Category.add({ data: body, api_token });
            Toast(response.message, 'success', false);
            setIsAddModal(false);
            return response.menuCategory
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err);
        }
    }
)
export const deleteCategoryManagementThunk = createAsyncThunk(
    'category/delete',
    async (data, { getState }) => {
        Toast("Deleting Category", 'loading', true);
        try {
            const { id } = data;
            const body = {
                category_id: id
            }
            const { api_token } = getState().profile.data;
            const response = await Category.delete({ data: body, api_token });
            Toast(response.message, 'success', false);
            return id
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err);
        }
    }
)


const initialState = {
    status: "pending",
    data: [],
}

export const categoryManagementSlice = createSlice({
    name: 'categoryManagement',
    initialState,
    reducers: {
        resetState: () => {
            return initialState
        },
    },
    extraReducers: {
        [fetchCategoryManagementThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [fetchCategoryManagementThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [fetchCategoryManagementThunk.rejected]: (state, action) => {
            state.status = "error"
        },
        [addCategoryManagementThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [addCategoryManagementThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [addCategoryManagementThunk.rejected]: (state, action) => {
            state.status = "success"
        },
        [deleteCategoryManagementThunk.fulfilled]: (state, action) => {
            state.data = state.data.filter(item => item.id != action.payload);
            state.status = "success"
        },
        [deleteCategoryManagementThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [deleteCategoryManagementThunk.rejected]: (state, action) => {
            state.status = "success"
        },
    }

})


// Action creators are generated for each case reducer function
export const { resetState } = categoryManagementSlice.actions

export default categoryManagementSlice.reducer