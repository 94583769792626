import React, { useEffect, useState } from "react";


/**Import Styles And Assets */
import styles from "./styles/BusinessDetail.module.scss"


/**Import Customized Component */
import { Business } from "../../Api/Business";
import { formattedDate, sliceText } from "../../Prototypes";
import { Toast } from "../../hooks/useToast";


/**Import Packaged Components */
import { Row, Col, Rate, Spin } from "antd";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import { Tooltip } from 'react-tooltip'
import { updateStatus } from "../../features/BusinessReducer";
import { setVisible } from "../../features/SearchReducer";


const antIcon = <LoadingOutlined style={{ fontSize: 35, color: "black" }} spin />;
const tabs = ['Business Reports', 'Posts Reports', 'Business Reviews'];


export default function BusinessDetail({ id, setBusinessId }) {

    const dispatch = useDispatch();
    const queryClient = useQueryClient()
    const { api_token } = useSelector(state => state.profile.data);
    const { status, error, isError, isLoading, data } = useQuery({
        queryKey: [`businessDetail/${id}`],
        retry: false,
        queryFn: () => Business.fetchDetailsById({ id, api_token })
    });
    const [tab, setTab] = useState(0);


    useEffect(() => {
        return () => dispatch(setVisible(true));
    }, [])


    const mutation = useMutation({
        mutationFn: (body) => Business.updateStatus({ data: body, api_token }),
        onSuccess: (res) => {
            Toast(res.message, 'success', false);
            dispatch(updateStatus({ id }))

            queryClient.setQueryData(
                [`businessDetail/${id}`],
                {
                    ...data,
                    businessData: [
                        {
                            ...data.businessData[0],
                            is_active: !data.businessData[0].is_active
                        }
                    ]
                }
            )
        },
        onError: (err) => Toast(err.message, 'error', false)
    })


    const handleTabChange = (e) => {
        const { value } = e.target;
        console.log(value)
        setTab(parseInt(value));

    }

    const getTab = () => {
        switch (tab) {
            case 0:
                return <ReportsTab data={data.bussinessReport} />
            case 1:
                return <PostsTab data={data.bussinessPostReport} />
            case 2:
                return <ReviewsTab data={data.reviewsData} />
        }
    }

    const handleUpdateStatus = (e) => {
        const { value } = e.target;
        const body = {
            business_id: id,
            is_active: value
        }
        Toast('Updating Business Status', 'loading', true);
        mutation.mutate(body);

    }



    return (
        <div className={styles.businessDetail}>


            <div className={styles.topContainer} onClick={() => setBusinessId(null)}>
                <h1 className={styles.back}><span className={styles.arrow}><AiOutlineArrowLeft /></span>Back</h1>
            </div>
            {(isLoading) ?
                <div className={styles.loadingContainer}>
                    <Spin indicator={antIcon} size="large" />
                </div> :
                (isError) ?
                    <div className={styles.loadingContainer}>
                        {Toast(error?.message, 'error', false)}
                        <h1 className={styles.error}>Business Details Not Found</h1>
                    </div> :
                    <>
                        <div className={styles.detailContainer}>
                            <div className={styles.info}>
                                <Row className={styles.row}>
                                    <Col span={8} className={`${styles.col} ${styles.imageContainer}`}>
                                        <img src={data?.businessData[0].business_image} alt="Business Image" className={styles.businessImage} />
                                    </Col>
                                    <Col span={16} className={`${styles.col}  ${styles.businessDetailContainer}`}>
                                        <div className={styles.businessHeaderContainer}>
                                            <h1 className={styles.businessName}>
                                                {data?.businessData[0].busimess_name}
                                            </h1>
                                            {data?.businessData[0].is_active ?
                                                <button className={`${styles.status} ${styles.active}`} value={1} onClick={handleUpdateStatus}>
                                                    Active
                                                </button> :
                                                <button className={`${styles.status} ${styles.deactive}`} value={0} onClick={handleUpdateStatus}>
                                                    Deactive
                                                </button>
                                            }
                                        </div>
                                        <p className={styles.businessEmail}>{data?.businessData[0].email}</p>
                                        <p className={styles.businessDescription}>{data?.businessData[0].business_desc}</p>
                                    </Col>
                                </Row>

                            </div>
                            <div className={styles.multiTabs}>
                                <div className={styles.tabsHeaderContainer}>
                                    {tabs.map((label, ind) => {
                                        return <button
                                            className={`${styles.tab} ${(ind === tab) ? styles.active : ""}`}
                                            value={ind}
                                            onClick={handleTabChange}
                                        >
                                            {label}
                                        </button>
                                    }
                                    )}


                                </div>
                                <div className={styles.tabContent}>
                                    {getTab()}

                                </div>

                            </div>

                        </div>
                    </>}
        </div >
    )
}


export function ReviewsTab({ data }) {
    return (
        !data.length ?
            <div className={styles.emptyContainer}>
                <h1 className={styles.emptyHeader}>Business Reviews Not Found</h1>

            </div> :
            <Row>
                {data.map(({ name, rate_star, rate_description, created_date, image_url }, ind) => <Col xxl={8} lg={12} className={styles.commentDetail} key={ind} >
                    <div className={styles.userInfo}>
                        <div className={styles.userImageContainer}>
                            <img src={image_url} alt="User Image" className={styles.userImage} />
                        </div>
                        <div className={styles.info}>
                            <div className={styles.namedateContainer}>
                                <p className={styles.name}>{name}</p>
                                <p className={styles.date}>{formattedDate(created_date)}</p>

                            </div>
                            <p className={styles.star}><Rate allowHalf disabled value={rate_star} /></p>
                        </div>
                    </div>
                    <div className={styles.userComment}>
                        <p
                            className={styles.commentText}
                            data-tooltip-id={`reviewContainer${ind}`}
                            data-tooltip-content={rate_description}
                        >
                            {sliceText(rate_description, 100)}
                        </p>
                        <Tooltip id={`reviewContainer${ind}`} style={{ maxWidth: '900px', width: "fit-content", zIndex: 10 }} />


                    </div>
                    <hr />
                </Col>
                )}
            </Row>
    )
}

export function ReportsTab({ data }) {
    return (
        !data.length ?
            <div className={styles.emptyContainer}>
                <h1 className={styles.emptyHeader}>Business Reports Not Found</h1>

            </div> :
            <Row>
                {data.map(({ name, email, image_url, reason, created_date }, ind) => <Col xxl={8} lg={12} className={styles.commentDetail} key={ind}>
                    <div className={styles.userInfo}>
                        <div className={styles.userImageContainer}>
                            <img src={image_url} alt="User Image" className={styles.userImage} />
                        </div>
                        <div className={styles.info}>
                            <div className={styles.namedateContainer}>
                                <p className={styles.name}>{name}</p>
                                <p className={styles.date}>{formattedDate(created_date)}</p>

                            </div>
                            <p className={styles.email}>{email}</p>
                        </div>
                    </div>
                    <div className={styles.userComment} >
                        <p
                            className={styles.commentText}
                            data-tooltip-id={`reportContainer${ind}`}
                            data-tooltip-content={reason}
                        >
                            {sliceText(reason, 100)}
                        </p>
                        <Tooltip id={`reportContainer${ind}`} style={{ maxWidth: '900px', width: "fit-content", zIndex: 10 }} />
                    </div>
                </Col>
                )
                }
            </Row >
    )
}

export function PostsTab({ data }) {
    return (
        !data.length ?
            <div className={styles.emptyContainer}>
                <h1 className={styles.emptyHeader}>Posts Reports Not Found</h1>

            </div> :
            <Row>
                {data.map(({ name, email, image_url, reason, post_title, created_date }, ind) => <Col xxl={8} lg={12} className={styles.commentDetail} key={ind}>
                    <div className={styles.userInfo}>
                        <div className={styles.userImageContainer}>
                            <img src={image_url} alt="User Image" className={styles.userImage} />
                        </div>
                        <div className={styles.info}>
                            <div className={styles.namedateContainer}>
                                <p className={styles.name}>{post_title}</p>
                                <p className={styles.date}>{formattedDate(created_date)}</p>

                            </div>
                            <p className={styles.email}>{email}</p>
                        </div>
                    </div>
                    <div className={styles.userComment} >
                        <p
                            className={styles.commentText}
                            data-tooltip-id={`reportContainer${ind}`}
                            data-tooltip-content={reason}
                        >
                            {sliceText(reason, 100)}
                        </p>
                        <Tooltip id={`reportContainer${ind}`} style={{ maxWidth: '900px', width: "fit-content", zIndex: 10 }} />
                    </div>
                </Col>
                )
                }
            </Row >
    )
}