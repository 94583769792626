import React, { useEffect, useMemo, useState } from "react";

/**Import Styles And Assets */
import styles from "./styles/AllBusiness.module.scss";

/**Import Customized Component and Prototypes */



/**Import Packaged Component */
import { useDispatch, useSelector } from "react-redux";
import { fetchBusinessThunk } from "../../features/BusinessReducer";
import TableLayout from "../../components/TableLayout";
import { useBusinessManagementHeader } from "../../schemas/tableHeaderSchema";
import { setVisible } from "../../features/SearchReducer";


/**CONSTANT DEFINATION */


const AllBusiness = ({ setBusinessId }) => {
    const header = useBusinessManagementHeader();
    const { data, status } = useSelector(state => state.business);
    const dispatch = useDispatch();
    const { search } = useSelector(state => state.search);
    const [list, setList] = useState(data);

    useEffect(() => {
        if (status !== 'success') {
            dispatch(fetchBusinessThunk());
        }

    }, [])


    const arr = useMemo(() => {
        return data.filter(item => {
            if (item.busimess_name.toLowerCase().includes(search.toLowerCase())) {
                return item
            }
        })
    }, [data, search])




    return (
        <div className={styles.businessManagement}>
            <div className={styles.title}>
                <h1 className={styles.contentTitle}>Business Management</h1>
            </div>
            <TableLayout
                headers={header}
                data={arr}
                status={status}
                rowClassName={"cursor"}
                onRowClick={(record, rowIndex) => {
                    return {
                        onClick: () => {
                            dispatch(setVisible(false));
                            setBusinessId(record.business_id)
                        }
                    }
                }} />
        </div>

    )
}


export default React.memo(AllBusiness);

