import React, { useEffect, useState } from "react";

import styles from "./index.module.scss";

import { Toast } from "../../hooks/useToast";


import { useDispatch, useSelector } from "react-redux";
import { validateEmail } from "../../Prototypes";
import { fetchStaticTextThunk, updateContactThunk } from "../../features/StaticTextReducer";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "black" }} spin />;


export default function ContactDetail() {


    const SUBMIT_BUTTON_TITLE = "Update";

    const { data: details, status } = useSelector(state => state.staticText);
    const dispatch = useDispatch();

    const [data, setData] = useState({
        email: details?.contact ? details.contact[0].email : "",
        mobile_no: details?.contact ? details.contact[0].numbers : "",
        description: details?.contact ? details.contact[0].description : ""
    })

    useEffect(() => {
        if (status !== 'success') {
            dispatch(fetchStaticTextThunk());

        }
    }, [])

    useEffect(() => {
        setData({
            email: details?.contact ? details.contact[0].email : "",
            mobile_no: details?.contact ? details.contact[0].numbers : "",
            description: details?.contact ? details.contact[0].description : ""
        })

    }, [details])

    function handleChange(name, value) {
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }


    async function handleSubmit() {

        if (status !== "success") return;
        if (!data.email) {
            return Toast('Email Should Not Be Empty', 'error', false);
        }
        if (!data.mobile_no) {
            return Toast('Mobile Number Should Not Be Empty', 'error', false);
        }
        if (!data.description) {
            return Toast('Description Should Not Be Empty', 'error', false);
        }
        if (!validateEmail(data.email)) {
            return Toast('Not A Valid Email', 'error', false);
        }

        dispatch(updateContactThunk(data));

    }

    return (
        <div className={styles.notification}>
            <h1 className={styles.contentTitle}>Update Profile </h1>
            <div className={styles.content}>
                <div className={styles.row}>
                    <h2>
                        Email
                    </h2>
                    <div className={styles.updateInput}>
                        {
                            (status !== "success") ?
                                <div className={styles.input}><Spin indicator={antIcon} size="large" /></div> :
                                <input
                                    type="text"
                                    value={data.email}
                                    onChange={(e) => handleChange('email', e.target.value)}
                                    placeholder="Email"
                                    className={styles.input}
                                />
                        }

                    </div>
                </div>
                <div className={styles.row}>
                    <h2>
                        Mobile Number
                    </h2>
                    <div className={styles.updateInput}>
                        {
                            (status !== "success") ?
                                <div className={styles.input}><Spin indicator={antIcon} size="large" /></div> :
                                <input
                                    type="text"
                                    value={data.mobile_no}
                                    onChange={(e) => handleChange('mobile_no', e.target.value)}
                                    placeholder="Mobile Number"
                                    className={styles.input}
                                />
                        }

                    </div>
                </div>
                <div className={styles.row}>
                    <h2>
                        Description
                    </h2>
                    <div className={styles.updateInput}>
                        {
                            (status !== "success") ?
                                <div className={styles.input}><Spin indicator={antIcon} size="large" /></div> :
                                <input
                                    type="text"
                                    value={data.description}
                                    onChange={(e) => handleChange('description', e.target.value)}
                                    placeholder="Description"
                                    className={styles.input}
                                />
                        }

                    </div>
                </div>
                <button className={styles.submit} onClick={handleSubmit}>
                    {SUBMIT_BUTTON_TITLE}
                </button>

            </div>

        </div>
    )
}