import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Business } from '../Api/Business';
import { Toast } from '../hooks/useToast';

export const fetchBusinessThunk = createAsyncThunk(
    'business/fetch',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await Business.fetch({ api_token });
            return response.restaurentData
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err);
        }
    }
)

export const updateBusinessStatusThunk = createAsyncThunk(
    'business/update/status',
    async (data, { getState }) => {
        Toast('Updating Status', 'loading', true);
        try {
            const { api_token } = getState().profile.data;
            const response = await Business.updateStatus({ data, api_token });
            Toast(response.message, 'success', false)
            return data
        } catch (err) {
            Toast(err.message, 'error', false)
            Toast(err.message, "error", false);
            return Promise.reject(err.message);
        }
    }
)



const initialState = {
    status: "pending",
    data: [],
}

export const businessSlice = createSlice({
    name: 'business',
    initialState,
    reducers: {
        resetState: () => {
            return initialState
        },
        updateStatus: (state, action) => {
            const { id } = action.payload
            return {
                ...state,
                data: state.data.map((item) => {
                    if (item.business_id != id) return item
                    return {
                        ...item,
                        is_active: !item.is_active
                    }
                })
            }
        }
    },
    extraReducers: {
        [fetchBusinessThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [fetchBusinessThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [fetchBusinessThunk.rejected]: (state, action) => {
            state.status = "error"
        },
        [updateBusinessStatusThunk.fulfilled]: (state, action) => {
            const { business_id, is_active } = action.payload;
            state.data = state.data.map((item) => {
                return (item.business_id != business_id) ?
                    item :
                    {
                        ...item,
                        is_active: !is_active
                    }
            })
            state.status = "success"
        },
        [updateBusinessStatusThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [updateBusinessStatusThunk.rejected]: (state, action) => {
            state.status = "error"
        }
    }

})


// Action creators are generated for each case reducer function
export const { resetState, updateStatus } = businessSlice.actions

export default businessSlice.reducer