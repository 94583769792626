import { Axios } from "../hooks/useAxiosInstance";

export const StaticText = (function () {

    // const { api_token } = useSelector(state => state.profile);

    const apis = () => ({
        fetch: async ({ api_token }) => await fetchRecord(api_token),
        insertFaq: async ({ data, api_token }) => await insertFaqRecord(data, api_token),
        deleteFaq: async ({ data, api_token }) => await deleteFaqRecord(data, api_token),
        updateFaq: async ({ data, api_token }) => await updateFaqRecord(data, api_token),
        updatePrivacy: async ({ data, api_token }) => await updatePrivacyRecord(data, api_token),
        updateTerms: async ({ data, api_token }) => await updateTermsRecord(data, api_token),
        updateInfo: async ( data, api_token ) => await updateInfoRecord(data, api_token),
    })

    async function fetchRecord(api_token) {
        const url = "https://api.cirgleapp.com/api/admin/static-text";
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.get(url, option);
    }



    async function insertFaqRecord(data, api_token) {
        const url = `https://api.cirgleapp.com/api/admin/add-faq`;
        const option = {
            headers: {
                'token': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }

    async function deleteFaqRecord(data, api_token) {
        const url = `https://api.cirgleapp.com/api/admin/delete-faq/${data.id}`;
        const option = {
            headers: {
                'token': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, {}, option);
    }

    async function updateFaqRecord(data, api_token) {
        const url = `https://api.cirgleapp.com/api/admin/update-faq`;
        const option = {
            headers: {
                'token': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }

    async function updatePrivacyRecord(data, api_token) {
        const url = `https://api.cirgleapp.com/api/admin/update-privacy_policy`;
        const option = {
            headers: {
                'token': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }

    async function updateTermsRecord(data, api_token) {
        const url = `https://api.cirgleapp.com/api/admin/update-static-text/terms-and-conditions`;
        const option = {
            headers: {
                'token': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }

    async function updateInfoRecord(data, api_token) {
        const url = `https://api.cirgleapp.com/api/admin/updateContact/`;
        const option = {
            headers: {
                'token': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }


    return apis();

})()
