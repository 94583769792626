import { Axios } from "../hooks/useAxiosInstance";

export const Category = (function () {


    const apis = () => ({
        fetch: async ({ api_token }) => await fetchRecord(api_token),
        add: async ({ data, api_token }) => await addRecord(data, api_token),
        delete: async ({ data, api_token }) => await deleteRecord(data, api_token),
    })

    async function fetchRecord(api_token) {
        const url = "https://api.cirgleapp.com/api/admin/categories";
        const option = {
            headers: {
                'token': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.get(url, option);
    }

    async function addRecord(data, api_token) {
        const url = "https://api.cirgleapp.com/api/admin/createCategory"
        const option = {
            headers: {
                'token': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }

    async function deleteRecord(data, api_token) {
        const url = "https://api.cirgleapp.com/api/admin/deleteCategory"
        const option = {
            headers: {
                'token': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }


    return apis();

})()
