import { useSelector } from "react-redux";
import { Axios } from "../hooks/useAxiosInstance";

export const Dashboard = (function () {

    // const { api_token } = useSelector(state => state.profile);

    const apis = () => ({
        fetch: async ({ api_token }) => await fetchRecord(api_token),
    })

    async function fetchRecord(api_token) {
        const url = "https://api.cirgleapp.com/api/admin/getDashStats";
        const option = {
            headers: {
                'token': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.get(url, option);
    }

    return apis();

})()
