import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Dashboard } from '../Api/Dashboard';


export const fetchDashboardThunk = createAsyncThunk(
    'dashboard',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await Dashboard.fetch({ api_token });
            return response.stats[0]
        } catch (err) {
            return Promise.reject(err);
        }
    }
)

const initialState = {
    status: "pending",
    data: {},
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setStatus: (state, actions) => {
            return {
                ...state,
                ...actions.payload
            }
        },
        resetState: () => {
            return initialState
        },
    },
    extraReducers: {
        [fetchDashboardThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [fetchDashboardThunk.pending]: (state, action) => {
            state.status = "loading"
        },
    }
})


// Action creators are generated for each case reducer function
export const { setStatus, resetState } = dashboardSlice.actions

export default dashboardSlice.reducer