import bin from "./images/bin.svg";
import arrow from "./images/arrow.svg";
import { useDispatch } from "react-redux"
import { updateReportManagementThunk } from "../features/ReportManagementReducer";



import { Tooltip } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import { updateUserManagementThunk } from "../features/UserManagementReducer";
import { BsFillStarFill } from "react-icons/bs"
import { deleteCategoryManagementThunk } from "../features/CategoryManagementReducer";
import { updateBusinessStatusThunk } from "../features/BusinessReducer";
import { roundOffTo } from "../Prototypes";

const STATUS_BUTTON_STYLE = {
    width: "100%",
    cursor: "pointer",
    border: "1px solid rgb(71,79,96,0.5)",
    borderRadius: "10px",
    padding: "3px 15px",
    display: "flex",
    alignItems: "center",
}
export function submit(title, message, cb, rejectCB = () => { }) {
    confirmAlert({
        title,
        message,
        buttons: [
            {
                label: 'Yes',
                onClick: () => cb()
            },
            {
                label: 'No',
                onClick: () => rejectCB()
            }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
    });
}


export function useUserManagementHeader() {

    const dispatch = useDispatch();
    return [
        {
            title: 'Username',
            render: (record, val) => {
                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <img src={val.image_url} alt="User Image" style={{ width: "30px", height: "30px", borderRadius: "100%" }} />
                    <p style={{ color: "#141414", margin: "0 15px" }}>{val.username}</p>

                </div>
            }
        },
        {
            title: 'Full Name',
            dataIndex: 'name',

        },
        {
            title: 'Email Address',
            dataIndex: 'email',
        },
        {
            title: 'Phone Number',
            dataIndex: 'mobile_no',
        },
        {
            title: 'Status',
            width: 170,
            key: 'action',
            render: (record) => {
                const status = (record.status == 0) ? 'unblock' : 'block';
                return <div
                    onClick={() =>
                        submit("Change Status", `Are you sure you want to change the status of this user?`, () => dispatch(updateUserManagementThunk({ user_id: record.id, status: record.status })))}
                    style={{
                        width: "100%",
                        cursor: "pointer",
                        border: "1px solid rgb(71,79,96,0.5)",
                        borderRadius: "10px",
                        padding: "3px 15px",
                        display: "flex",
                        alignItems: "center",
                    }}>
                    <a
                        style={{ width: "100%", marginRight: "30px", color: (record.status != "0") ? "#3ABF51" : "#CB0C51" }}
                    >
                        {record.status != "0" ? "Active" : "Deactive"}
                    </a>
                    <img src={arrow} alt="Arrow" width={20} height={20} />
                </div>
            },
        },
    ];
}



export function useReportManagementHeader() {
    const dispatch = useDispatch();
    const header = [
        {
            title: 'Reporter',
            render: (record) => {
                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <img src={record.reportedBy_image_url} alt="User Image" style={{ width: "30px", height: "30px", borderRadius: "100%" }} />
                    <p style={{ color: "#141414", margin: "0 15px" }}>{record.reportedBy_name}</p>

                </div>
            }
        },
        {
            title: 'Reporter Email',
            dataIndex: 'reportedBy_email',
        },
        {
            title: 'Reportee Username',
            render: (record) => {
                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <img src={record.reported_image_url} alt="User Image" style={{ width: "30px", height: "30px", borderRadius: "100%" }} />
                    <p style={{ color: "#141414", margin: "0 15px" }}>{record.reported_name}</p>

                </div>
            }
        },
        {
            title: 'Reportee Email',
            dataIndex: 'reported_email',
        },
        {

            title: 'Reason',
            dataIndex: 'reason',
            ellipsis: {
                showTitle: false,
            },
            render: (text) => (
                <Tooltip placement="bottom" title={text} overlayStyle={{ width: "auto", maxWidth: "40vw" }}>
                    {(text.length > 10) ? `${text.slice(0, 15)}...` : text}
                </Tooltip>
            ),
        },
        {
            title: 'Date',
            width: 250,
            ellipsis: true,
            dataIndex: 'reported_date',
        },
        {
            title: 'Status',
            width: 170,
            key: 'action',
            render: (record) => {
                return <div
                    style={{
                        width: "100%",
                        cursor: "pointer",
                        border: "1px solid rgb(71,79,96,0.5)",
                        borderRadius: "10px",
                        padding: "3px 15px",
                        display: "flex",
                        alignItems: "center",
                    }}
                    onClick={() => submit(
                        "Change Status",
                        "Are you sure you want to change the status of reported user?",
                        () => dispatch(updateReportManagementThunk({ user_id: record.reported_id, status: record.reported_status }))
                    )}
                >
                    {record.reported_status ?
                        < a style={{ width: "100%", color: "#3ABF51" }}>Unblock</a> :
                        < a style={{ width: "100%", color: "#CB0C51" }}>Block</a>
                    }
                    <img src={arrow} alt="Arrow" width={20} height={20} style={{ marginLeft: "20px" }} />
                </div>
            },
        },
    ];
    return header;

}




export function useCategoryManagementHeader() {

    const dispatch = useDispatch();
    return [
        {
            title: 'Category Name',
            dataIndex: 'name',

        },
        {
            title: 'Action',
            width: 170,
            key: 'action',
            render: (record) => {
                return <a
                    onClick={() => submit(
                        "Confirm Deletion",
                        "Are you sure you want to delete this category?",
                        () => dispatch(deleteCategoryManagementThunk(record))
                    )}
                >
                    Delete
                </a>

            },
        },
    ];
}



export function useBusinessManagementHeader() {

    const dispatch = useDispatch();
    return [
        {
            title: 'Business Name',
            render: (record, val) => {
                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <img src={val.business_image} alt="User Image" style={{ width: "30px", height: "30px", borderRadius: "100%" }} />
                    <p style={{ color: "#141414", margin: "0 15px" }}>{val.busimess_name}</p>

                </div>
            }
        },
        {
            title: 'Location',
            dataIndex: 'business_address',
        },
        {
            title: 'Business Rating',
            render: (record, val) => {
                return <p style={{ color: "#141414", margin: "0 15px", display: "flex", flexDirection: "row", alignItems: "center", margin: 0 }}>
                    <span style={{ marginRight: "10px" }}>
                        <BsFillStarFill style={{ color: "#ffbc00", display: "flex", alignItems: "center", }} />
                    </span>
                    {roundOffTo(val.average_rating,1)}
                </p>
            }
        },
        {
            title: 'Status',
            width: 170,
            key: 'action',
            onCell: record => {
                return {
                    onClick: event => {
                        event.stopPropagation();
                    }
                }
            },
            render: (record) => {
                const status = (record.is_active == 0) ? 'deactive' : 'active';
                return <div
                    onClick={() =>
                        submit("Change Status", `Are you sure you want to change the status of this business?`, () => dispatch(updateBusinessStatusThunk({ business_id: record.business_id, is_active: record.is_active })))}
                    style={STATUS_BUTTON_STYLE}>
                    <a
                        style={{ width: "100%", marginRight: "30px", color: (record.is_active != "0") ? "#3ABF51" : "#CB0C51" }}
                    >
                        {record.is_active != "0" ? "Active" : "Deactive"}
                    </a>
                    <img src={arrow} alt="Arrow" width={20} height={20} />
                </div>
            },
        },
    ];
}
