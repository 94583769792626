import { Axios } from "../hooks/useAxiosInstance";

export const Profile = (function () {

    // const { api_token } = useSelector(state => state.profile);

    const apis = () => ({
        post: async (data) => await postRecord(data),
        postForgetPassword: async (data) => await postForget(data),
        update: async (data, api_token) => await updatePassword(data, api_token)
    })

    async function postRecord(data) {
        const url = "https://api.cirgleapp.com/api/admin/login";
        const option = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }
    async function postForget(data) {
        const url = "https://api.cirgleapp.com/api/admin/forget-password";
        const option = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }

    async function updatePassword(data, api_token) {
        const url = "https://api.cirgleapp.com/api/admin/change-password";
        const option = {
            headers: {
                'token': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }

    return apis();

})()
