import React, { useEffect, useState } from "react";

/**Import Styles and Images */
import styles from "./index.module.scss"

import TableLayout from "../../components/TableLayout";
import { useCategoryManagementHeader } from "../../schemas/tableHeaderSchema";


import { useSelector, useDispatch } from "react-redux";
import { addCategoryManagementThunk, fetchCategoryManagementThunk } from "../../features/CategoryManagementReducer";
import CategoryModal from "../../components/Modal/CategoryModal";
import { Toast } from "../../hooks/useToast";
import { resetValue } from "../../features/SearchReducer";


export default function CategoryManagement() {
    const header = useCategoryManagementHeader();
    const { data, status } = useSelector(state => state.category);
    const { search } = useSelector(state => state.search);
    const [list, setList] = useState(data);
    const dispatch = useDispatch();
    const [isAddModal, setIsAddModal] = useState(false);


    useEffect(() => {
        if (status !== "success") {
            dispatch(fetchCategoryManagementThunk());
        }

        return () => dispatch(resetValue());
    }, [])

    useEffect(() => {
        if (!search) return setList(data);


        const array = [
            ...data.map(item => {
                if (item.name.toLowerCase().includes(search.toLowerCase())) {
                    return item
                }
            })
        ].filter(Boolean)
        setList(array);

    }, [data, search])


    const handleAddClick = () => {
        setIsAddModal(true);
    }

    const handleSubmit = (data) => {
        if (!data) return Toast("Category Should Not Be Empty", 'error', false);
        dispatch(addCategoryManagementThunk({ name: data, setIsAddModal }));

    }

    return (
        <div className={styles.categoryManagement}>
            {isAddModal ? <CategoryModal setIsOpen={() => setIsAddModal(false)} handleSubmit={handleSubmit} /> : ""}
            <div className={styles.title}>
                <h1 className={styles.contentTitle}>Business Category Management</h1>
                <button className={styles.addButton} onClick={handleAddClick}>+ Add New</button>
            </div>
            <TableLayout headers={header} data={list} status={status} />
        </div>
    )
}